export class User {
    id: string;
    email: string;
    name: string;
    username: string;
    password: string;
    createdAt: Date;
    updatedAt: Date;
    roles: string[];
    isAuthenticated: boolean;

    constructor() {
        this.id = '';
        this.username = '';
        this.password = '';
        this.email = '';
        this.createdAt = new Date();
        this.updatedAt = new Date();
        this.roles = [];
        this.isAuthenticated = false;
    }
}