import { FormField } from "./formField.model";
import { TourType } from "./tourType.model";

export class Form {
    id?: number;
    tenantId?: number;
    tourTypeId?: number;

    startDate: Date;
    endDate: Date;
    
    tourType?: TourType;
    formFields: FormField[];

    constructor() {
        this.id = 0;
        this.tenantId = 0;
        this.tourTypeId = 0;
        this.tourType = undefined;
    }
}