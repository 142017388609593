import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { notAbpAuthGuard } from './core/guards/auth-guard.guard';
import { LoginComponent } from './modules/login/login/login.component';
import { MainComponent } from './modules/main/main.component';

const routes: Routes = [
  {
    path: 'loginOffline',
    pathMatch: 'prefix',
    component: LoginComponent,
  },
  {
    path: '',
    component: MainComponent,
    canActivate: [notAbpAuthGuard],
    pathMatch: 'prefix',
    children: [
      {
        path: 'home', 
        loadChildren: () => import('./modules/home/home.module').then(m => m.HomeModule),
      },
      {
        path: 'tourType',
        loadChildren: () => import('./modules/tourType/tour-type.module').then(m => m.TourTypeModule),
      },
      {
        path: 'form',
        loadChildren: () => import('./modules/form/form.module').then(m => m.FormModule),
      },
      {
        path: 'action',
        loadChildren: () => import('./modules/actions/actions.module').then(m => m.ActionsModule),
      },
      {
        path: 'executedActions',
        loadChildren: () => import('./modules/executed-actions/executed-actions.module').then(m => m.ExecutedActionsModule),
      },
      {
        path: 'tour',
        loadChildren: () => import('./modules/tours/tours.module').then(m => m.ToursModule),
      },
      {
        path: 'setting-management',
        loadChildren: () => import('./modules/settings/settings.module').then(m => m.SettingsModule),
      },
      {
        path: 'identity',
        loadChildren: () => import('@volo/abp.ng.identity').then(m => m.IdentityModule.forLazy()),
      },
      {        
        path: '**',
        redirectTo: 'home',
      }
    ]
  },
  {
    path: '**',
    redirectTo: 'home',
  }
];


@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {}